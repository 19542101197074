<template>
  <el-drawer
    title="次卡紀錄詳情"
    :visible.sync="syncShow"
    :append-to-body="true"
    destroy-on-close
    size="600px"
    class="classTicket-record-detail"
    @close="$emit('close')"
  >
    <div style="padding: 20px">
      <PunchCardInfoBlock :data="selectPunchCard" />
      <div v-if="get(selectPunchCard, 'status') ==='open' && isMemberProfile" class="flex justify-end">
        <BaseElButton
          plain
          class="btn"
          type=""
          @click="$emit('cancel')"
        >
          作廢整份堂票
        </BaseElButton>
      </div>
      <!-- <BaseElForm
        label-width="150px"
        label-position="left"
        :model="formData"
        :rules="formRules"
        ref="formRef"
      >
        <BaseElFormItem label="使用次數" prop="useTimes">
          <BaseElInput v-model="formData.useTimes" clearable />
          <BaseElButton
            type="primary"
            @click="onConfirm"
          >
            確認
          </BaseElButton>
        </BaseElFormItem>
      </BaseElForm> -->
      <!-- <ClassTicketUseList
        :data="selectPunchCard"
        :classTicketRecordSerials="classTicketRecordSerials"
      />
      <ClassTicketSerialList
        :classTicketRecordSerials="classTicketRecordSerials"
      /> -->
    </div>
  </el-drawer>
</template>

<script>
import { defineComponent, computed } from 'vue'
import PunchCardInfoBlock from './PunchCardInfoBlock.vue'
// import ClassTicketUseList from './ClassTicketUseList.vue'
// import ClassTicketSerialList from './ClassTicketSerialList.vue'
import { get } from 'lodash'
import { useRoute } from 'vue-router/composables'
// import BaseElForm from '@/components/BaseElForm.vue'
import BaseElButton from '@/components/BaseElButton.vue'
export default defineComponent({
  name: 'ClassTicketRecordDetail',
  components: {
    PunchCardInfoBlock,
    // ClassTicketUseList,
    // ClassTicketSerialList,
  },
  props: {
    show: Boolean,
    selectPunchCard: Object,
    // classTicketRecordSerials: Object,
  },
  setup (props, { emit }) {
    const route = useRoute()
    // 會員模組 - 堂票紀錄才顯示
    const isMemberProfile = computed(() => route.name === 'MemberProfile')
    return { get, isMemberProfile }
  },
  computed: {
    syncShow: {
      get () {
        return this.show
      },
      set (data) {
        this.$emit('opend:show', data)
      },
    },
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item {
@apply mb-0;
}

.section-block-title {
@apply text-primary-100 font-medium text-[18px] pb-[12px];
}
::v-deep .el-drawer__header {
@apply mb-0;
}

</style>

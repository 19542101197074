export const punchCardStatusConfig = {
  available: { label: '可使用', value: 'available', tagType: 'action' },
  used: { label: '使用完畢', value: 'used', tagType: 'info' },
  expired: { label: '已過期', value: 'expired', tagType: 'info' },
  void: { label: '已作廢', value: 'void', tagType: 'info' },
}

export const punchCardOriginConfig = {
  admin: { label: '後台新增', value: 'admin' },
  memberStore: { label: '會員商城', value: 'memberStore' },
  subscription: { label: '訂閱方案', value: 'subscription' },
}

export const punchCardUseRecordOriginConfig = {
  systemExpire: { label: '系統過期', value: 'systemExpire' },
  adminVoid: { label: '後台作廢', value: 'adminVoid' },
  adminUse: { label: '後台使用', value: 'adminUse' },
  adminAdd: { label: '後台補發', value: 'adminAdd' },
  entryControlUse: { label: '進場使用', value: 'entryControlUse' },
  appointmentUse: { label: '預約使用', value: 'appointmentUse' },
}

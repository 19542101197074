<template>
  <div class="class-ticket-record">
    <PageTitle
      title="次卡紀錄"
      btn="匯出新增紀錄"
      btn2="匯出使用紀錄"
      btnPlain
      @btnClick=";(exportType = 'add'), (dialog.export = true)"
      @btn2Click=";(exportType = 'use'), (dialog.export = true)"
    />

    <FiltersContainer>
      <MemberSearch
        :model.sync="search.member"
        @change="refresh"
        @clear="refresh(true)"
      />
      <BaseElInput
        v-model="search.code"
        style="max-width: 400px"
        clearable
        placeholder="搜尋次卡編號"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i
          slot="prefix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </BaseElInput>

      <BaseElInput
        v-model="search.name"
        style="max-width: 400px"
        clearable
        placeholder="搜尋次卡名稱"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i
          slot="prefix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </BaseElInput>
      <BaseElSelect
        v-model="search.status"
        style="max-width: 400px"
        clearable
        placeholder="狀態"
        @change="refresh(true)"
      >
        <BaseElSelectOption
          v-for="item in punchCardStatusConfig"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </BaseElSelect>
    </FiltersContainer>

    <section>
      <BaseTable
        v-loading="loading.table"
        :data="tableData"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn label="持有會員" fiexed="left" width="110">
          <template slot-scope="scope">
            <div class="underline cursor-pointer" @click="goToProfile(scope.row)">{{ get(scope.row ,'Member.UserInfo.name') }}</div>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          prop="code"
          label="次卡編號"
          width="115"
          align="center"
        />
        <BaseElTableColumn
          prop="name"
          label="次卡名稱"
          width="115"
          align="center"
        />
        <BaseElTableColumn prop="status" label="狀態" align="center" width="110">
          <template slot-scope="scope">
            <Tag :type="selectStatus(scope.row.status, 'tagType')">
              {{ selectStatus(scope.row.status, 'label') }}
            </Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="status" label="餘額/總次數" align="center" width="110">
          <template slot-scope="scope">
            {{ scope.row.availableTimes }} / {{ scope.row.totalTimes }}
            <!-- TODO: 無上限 Or 過期作廢顯示方式不同 -->
          </template>
        </BaseElTableColumn>
        <!-- <BaseElTableColumn
          prop="Member.UserInfo.name"
          label="姓名"
          fixed="left"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.Member ? scope.row.Member.UserInfo.name : '非會員' }}
          </template>
        </BaseElTableColumn> -->
        <!-- <BaseElTableColumn label="可用張數" align="center">
          <template slot-scope="scope">
            {{ scope.row.availableTimes }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn label="已用張數" align="center">
          <template slot-scope="scope">
            {{ scope.row.usedTimes }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          v-if="useTicketPermission"
          label="已贈張數"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.giveTimes }}
          </template>
        </BaseElTableColumn> -->
        <!-- <BaseElTableColumn prop="availableUseTimes" label="剩餘張數" align="center" /> -->
        <!-- <BaseElTableColumn prop="availableTimes" label="總張數" align="center" /> -->
        <BaseElTableColumn prop="price" label="價格" align="center" />
        <!-- <BaseElTableColumn
          prop="origin"
          label="新增來源"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            {{ mapOrigin(scope.row.origin) }}
          </template>
        </BaseElTableColumn> -->
        <!-- <BaseElTableColumn
          v-if="useStorePermission"
          prop="Branch"
          label="操作單位"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.Branch?.name || '-' }}
          </template>
        </BaseElTableColumn> -->
        <!-- <BaseElTableColumn prop="isExp" label="有效期限" align="center">
          <template slot-scope="scope">
            <Tag disable-transitions :type="tagType(scope.row.isExp)">
              {{ scope.row.isExp ? '有' : '無' }}
            </Tag>
          </template>
        </BaseElTableColumn> -->
        <!-- <BaseElTableColumn
          prop="note"
          label="備註"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.note || '-' }}
          </template>
        </BaseElTableColumn> -->
        <BaseElTableColumn
          prop="createdAt"
          label="建立時間"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createdAt) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="exp" label="有效期限" width="120" align="center">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.exp) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          prop="origin"
          label="來源"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            {{ mapOrigin(scope.row.origin) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn label="操作" fixed="right" align="center" width="140">
          <template slot-scope="scope">
            <TableEditBtnGroup
              v-if="scope.row.Member"
              editBtn="詳情"
              deleteBtn="作廢"
              @edit="findPunchCard(scope.row)"
              :hideDelete="scope.row.status !== 'available'"
              @delete="deleteDialog = true"
            />
            <!-- <div v-if="scope.row.Member" class="table-edit-btn-group">
              <BaseElButton type="text" class="table-opt-edit" @click="goToProfile(scope.row)">前往會員</BaseElButton>
            </div> -->
            <div v-else>-</div>
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh(false)"
      />
    </section>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="作廢後將無法復原，確定要作廢？"
      width="40%"
      btnString="刪除"
      @close="deleteDialog = false"
      @delete="voidPunchCard(), deleteDialog = false"
    />
    <PunchCardRecordDetail
      :show="showDrawer"
      :selectPunchCard="selectRow"
      @close="closeDrawer"
      @cancel="deleteDialog = true"
      @refresh="refresh"
    />
    <ExportOptionsDialog
      v-if="dialog.export"
      :useExportTask="ExportType = 'add' ? useClassTicketRecord : useClassTicketRecordUse"
      @close="dialog.export = false"
      @export="prepareExport"
    />
    <ExportDialog
      v-if="exportState.modal"
      title="匯出堂票紀錄"
      :inProgress="exportState.exportting"
      :isError="exportState.error"
      :content="exportState.content"
      :percentage="totalPercentege"
      @close="resetExport"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, ref, computed, onActivated, onMounted } from 'vue'
import { mapGetters } from 'vuex'
import MemberSearch from '@/components/Search/MemberSearch.vue'
// import ClassTicketSearch from '@/components/Search/ClassTicketSearch.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import PunchCardRecordDetail from '@/views/PunchCard/components/PunchCardRecordDetail.vue'
import {
  GetClassTicketRecord,
  GetClassTicketRecordCount,
  GetClassTicketUseRecord,
  GetClassTicketUseRecordCount,
  // GetClassTicketRecordSerial,
  // GetClassTicketRecordSerialDetail,
  GetClassTicketRecordSerialOld,
  GetClassTicketRecordSerialCount,
} from '@/api/classTicket'
import {
  GetPunchCardRecord, GetPunchCardRecordCount
} from '@/api/punchCard'
import { CreateClassTicketRecordSheetExportTask, CreateClassTicketRecordUseSheetExportTask } from '@/api/exportTask'
import { pageStartIndex } from '@/utils/table'
import { formatDate } from '@/utils/date'
import { checkUserFeature } from '@/store/modules/permission'
// import MixinFunc from '@/components/MixinFunc'
import {  ExportMoreSheetExcel } from '@/utils/excel'
import {
  classTicketUseRecordOriginConfig,
  classTicketUseRecordTypeConfig,
} from '@/config/classTicket'
import { punchCardStatusConfig } from '@/config/punchCard'
import { useClassTicket } from '@/use/classTicket'
import { useExport } from '@/use/export'
import { get, map, join } from 'lodash'
import dayjs from '@/lib/dayjs'
import { useShop } from '@/use/shop'
import { usePermissions } from "@/use/permissions"
import { useExportCenter } from '@/use/useExportCenter'
import { useTable } from '@/use/table'
import { useRouter } from 'vue-router/composables'
import BaseElSelect from '@/components/BaseElSelect.vue'
export default defineComponent({
  name: 'PunchCardRecord',
  components: {
    MemberSearch,
    EmptyBlock,
    // ClassTicketSearch,
    ExportOptionsDialog,
    DeleteDialog,
    ExportDialog,
    PunchCardRecordDetail,
  },
  // mixins: [MixinFunc],
  setup (props) {
    const { exportState, resetExport, getExportDataOld, totalDataCount, totalPercentege, curTotalDataCount } = useExport()
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      fetchData,
      fetchDataCount,
      dateFormat,
    } = useTable()
    const { classTicketStatusLabel } = useClassTicket()
    const { checkAction } = usePermissions()
    const { shopId } = useShop()
    const router = useRouter()
    const { messageOptions } = useExportCenter()
    const exportType = ref('add')
    const search = reactive({
      code: '',
      name: '',
      status: null,
      // classTicket: '',
      member: null,
      // MemberId: null,
    })
    const dialog = reactive({
      export: false,
    })
    const showDrawer = ref(false)
    const selectRow = ref(null)
    const deleteDialog = ref(false)
    const useClassTicketRecordUse = computed(() => checkAction('admin.classTicketRecordUse.createSheetExportTask'))
    const useClassTicketRecord = computed(() => checkAction('admin.classTicketRecord.createSheetExportTask'))

    const selectStatus = (status, attr) => {
      return get(punchCardStatusConfig[status], attr)
    }
    const mapOrigin = (key) => {
      const origins = {
        system: '系統',
        admin: '會員後台',
        sales: '銷售紀錄',
        give: '會員轉贈',
        gift: '禮物贈送',
        memberStore: '會員商城',
        eventPlaybook: '系統行銷活動發放',
      }
      return origins[key] || '-'
    }
    const voidPunchCard = async () => {
      // TODO: 作廢次卡
    }
    const formatAddRecordData = (item) => {
      return {
        堂票編號: item.code,
        姓名: get(item, 'Member.UserInfo.name') || '非會員',
        堂票名稱: item.name,
        狀態: classTicketStatusLabel(item),
        可用張數: item.availableTimes,
        已用張數: item.usedTimes,
        已贈張數: item.giveTimes,
        價格: item.price,
        新增來源: mapOrigin(item.origin),
        有效期限: item.isExp ? '有' : '無',
        有效日期: item.exp ? formatDate(item.exp) : '-',
        備註: item.note,
        建立時間: formatDate(item.createdAt),
      }
    }
    const formatAddRecordSerialData = (item) => {
      return {
        堂票序號編號: get(item, 'code'),
        堂票序號狀態: classTicketStatusLabel(item),
        會員姓名: get(item, 'ClassTicketRecord.Member.UserInfo.name') || '非會員',
        堂票編號: get(item, 'ClassTicketRecord.code'),
        堂票名稱: get(item, 'ClassTicketRecord.name'),
        堂票狀態: classTicketStatusLabel(get(item, 'ClassTicketRecord')),
        有效期限: get(item, 'ClassTicketRecord.isExp') ? '有' : '無',
        有效日期: get(item, 'ClassTicketRecord.exp') ? formatDate(get(item, 'ClassTicketRecord.exp')) : '-',
        新增來源: mapOrigin(get(item, 'ClassTicketRecord.origin')),
        是否履約: get(item, 'TicketPaymentPromise.ticketPromiseRecordId') ? '是' : '否',
        // 是否已送履約核銷: '-',
        建立時間: formatDate(item.createdAt),
      }
    }
    const formatUseRecordData = (item) => {
      return {
        使用時間: formatDate(item.createdAt),
        堂票名稱: get(item, 'ClassTicketRecord.name'),
        堂票編號: get(item, 'ClassTicketRecord.code'),
        使用來源: get(classTicketUseRecordOriginConfig, `${item.origin}.label`),
        使用門市: get(item, 'Branch.name'),
        會員名稱:
          get(item, 'ClassTicketRecord.Member.UserInfo.name') || '非會員',
        使用類型: get(classTicketUseRecordTypeConfig, `${item.type}.label`),
        '使用/取消': item.isCancelType ? '取消' : '使用',
        '使用/取消次數': Math.abs(item.times),
        堂票序號陣列: join(
          map(item.ClassTicketRecordSerials, 'code'),
          ',',
        ),
      }
    }
    const prepareExport = async ({ all, range }) => {
      dialog.export = false
      const payload = {
        shopId: shopId.value,
        MemberId: get(search.member, 'id') || undefined,
        code: search.code || undefined,
        ClassTicketId: search.classTicket || undefined,
        createdAtStart: get(range, '[0]') ? dayjs(get(range, '[0]')).toDate() : undefined,
        createdAtEnd: get(range, '[0]') ? dayjs(get(range, '[1]')).toDate() : undefined,
      }

      if (exportType.value === 'use' && useClassTicketRecordUse.value) {
        await createSheetExportTask(CreateClassTicketRecordUseSheetExportTask, payload)
      } else if (exportType.value === 'add' && useClassTicketRecord.value) {
        await createSheetExportTask(CreateClassTicketRecordSheetExportTask, payload)
      } else {
        exportState.modal = true
        exportState.exportting = true
        try {
          let res = []
          if (exportType.value === 'add') {
            res = await Promise.all([
              GetClassTicketRecordCount(payload),
              GetClassTicketRecordSerialCount(payload, { oldInstance: true }),
            ])
          } else if (exportType.value === 'use') {
            res = await Promise.all([
              GetClassTicketUseRecordCount(payload),
            ])
          }
          console.log('res', res)
          exportState.dataCount = res
          console.log('exportState.dataCount', exportState.dataCount)
          if (!totalDataCount.value) {
            exportState.content = '尚無資料可匯出'
            exportState.error = true
            window.$message.warning('尚無資料可匯出')
            return
          }

          const exportData = {
            recordData: [],
            useRecordData: [],
            serialData: [],
          }
          const sheets = []

          if (exportType.value === 'add') {
            const [recordData, serialData] = await Promise.all([
              getExportDataOld({ stage: 0, fetchAPI: GetClassTicketRecord, payload }),
              getExportDataOld({ stage: 1, fetchAPI: GetClassTicketRecordSerialOld, payload }),
            ])

            for (const subitem of serialData) {
              const row = formatAddRecordSerialData(subitem)
              exportData.serialData.push(row)
            }
            for (const item of recordData) {
              exportData.recordData.push(formatAddRecordData(item))
            }
            sheets.push({
              sheetName: '堂票新增紀錄',
              data: exportData.recordData,
            })
            sheets.push({
              sheetName: '堂票序號新增紀錄',
              data: exportData.serialData,
            })
          } else if (exportType.value === 'use') {
            const [useRecordData] = await Promise.all([
              getExportDataOld({ stage: 0, fetchAPI: GetClassTicketUseRecord, payload }),
            ])
            for (const item of useRecordData) {
              exportData.useRecordData.push(formatUseRecordData(item))
            }
            sheets.push({
              sheetName: '堂票使用紀錄',
              data: exportData.useRecordData,
            })
          }

          exportState.success = true
          exportState.content = '匯出完成'
          if (exportType.value === 'use') {
            ExportMoreSheetExcel(sheets, '堂票使用紀錄')
          } else if (exportType.value === 'add') {
            ExportMoreSheetExcel(sheets, '堂票新增紀錄')
          }
        } catch (error) {
          exportState.exportting = false
          exportState.error = true
        }
      }
    }
    const createSheetExportTask = async (apiFunc, payload) => {
      const [, err] = await apiFunc(payload)
      if(err) {
        window.$message.error(err)
        return
      }
      window.$message(messageOptions.value)
    }
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        code: search.code || undefined,
        MemberId: search.member ? search.member.id : undefined,
        name: search.name || undefined,
        status: search.status || undefined,
      }
      await Promise.allSettled([
        fetchData(GetPunchCardRecord, payload),
        fetchDataCount(GetPunchCardRecordCount, payload),
      ])
    }
    const refresh = async (resetPage) => {
      if (resetPage) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }
    const closeDrawer = () => {
      showDrawer.value = false
      selectRow.value = null
    }
    const findPunchCard = async (row) => {
      selectRow.value = row
      showDrawer.value = true
    }
    const goToProfile = (row) => {
      router.push(`/members/member-profile/${row.MemberId}/classTicket`) // TODO: change link
    }
    onMounted( async() => {
      await refresh()
    })
    onActivated( async() => {
      await refresh()
    })
    return {
      search,
      ExportMoreSheetExcel,
      prepareExport,
      totalPercentege,
      resetExport,
      totalDataCount,
      curTotalDataCount,
      classTicketStatusLabel,
      mapOrigin,
      exportState,
      dialog,
      get,
      exportType,
      useClassTicketRecordUse,
      useClassTicketRecord,
      punchCardStatusConfig,
      selectStatus,
      voidPunchCard,
      refresh,
      loading,
      tableData,
      tableDataCount,
      tableOptions,
      goToProfile,
      closeDrawer,
      deleteDialog,
      selectRow,
      showDrawer,
      dateFormat,
    }
  },
  // data: () => ({
    // member: '',
    // loading: false,
    // showDialog: false,
    // dialogType: 'create',
    // deleteDialog: false,

    // warning: {
    //   type: null,
    //   error: null,
    //   total: 0,
    //   success: 0,
    //   fail: 0,
    // },
    // processing: {
    //   task: null,
    //   detail: null,
    //   title: '',
    //   inProgress: false,
    //   isError: false,
    //   percentage: 0,
    // },

    // classTicketRecordCount: 0,
    // classTicketRecordList: [],

    // tableOptions: {
    //   page: 1,
    //   pageLimit: 10,
    // },
    // selectRow: null,
    // showDrawer: false,
    // classTicketRecordSerials: {},
  // }),

  // computed: {
    // ...mapGetters(['shop', 'userFeatures', 'userPlanFeature']),
    // pageStartIndex () {
    //   return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    // },
    // useStorePermission () {
    //   return checkUserFeature(
    //     this.userPlanFeature,
    //     this.userFeatures,
    //     'admin.branch.adminUseStore',
    //   )
    // },
    // useTicketPermission () {
    //   return checkUserFeature(
    //     this.userPlanFeature,
    //     this.userFeatures,
    //     'admin.classTicketConfig.clientGive',
    //   )
    // },
  // },
  // async mounted () {
  //   await this.refresh()
  // },
  // async activated () {
  //   await this.refresh()
  // },

  // methods: {
    // openExportDialog () {
    //   if (!this.filteredExportFormatOptions.length) {
    //     return this.$message.error('無此權限')
    //   } else {
    //     this.exportFormData.format = this.filteredExportFormatOptions[0].value
    //   }

    //   this.showExportDialog = true
    // },
    // classTicketTagType (val) {
    //   let type = 'info'
    //   if (val === 'open') type = 'action'
    //   if (val === 'cancel') type = 'danger'
    //   if (val === 'overdue') type = 'warning'
    //   return type
    // },
    // tagType (val) {
    //   let type = 'info'
    //   if (val === 'open') type = 'action'
    //   if (val === 'cancel') type = 'danger'
    //   if (val === 'overdue') type = 'warning'
    //   return type
    // },
    // async recordSerial () {
    //   const [res, err] = await GetClassTicketRecordSerial({
    //     shopId: this.shop,
    //     start: 0,
    //     limit: 100,
    //   })
    //   if (err) this.$message.error(err)
    //   return res
    // },

    // openDialog (type) {
    //   this.dialogType = type
    //   this.showDialog = true
    // },

    // async refresh (resetPage) {
    //   if (resetPage) this.tableOptions.page = 1
    //   this.loading = true
    //   await this.getClassTicketRecord()
    //   await this.getClassTicketRecordCount()
    //   this.loading = false
    // },
    // async findPunchCard (row) {
    //   this.selectRow = row
    //   this.getClassTicketSerial()
    //   this.showDrawer = true
    // },
    // async getClassTicketSerial () {
    //   const [res, err] = await GetClassTicketRecordSerialDetail({
    //     shopId: this.shop,
    //     id: this.selectRow.id,
    //   })
    //   if (err) window.$message.error(err)
    //   this.classTicketRecordSerials = res
    // },
    // closeDrawer () {
    //   this.showDrawer = false
    //   this.selectRow = null
    //   this.classTicketRecordSerials = {}
    // },
    // goToProfile (row) {
    //   this.$router.push(`/members/member-profile/${row.MemberId}/classTicket`)
    // },

    // async getClassTicketRecord () {
    //   try {
    //     const res = await GetClassTicketRecord({
    //       shopId: this.shop,
    //       start: this.pageStartIndex,
    //       limit: this.tableOptions.pageLimit,
    //       code: this.search.code || undefined,
    //       MemberId: this.search.member ? this.search.member.id : undefined,
    //       ClassTicketId: this.search.classTicket || undefined,
    //     })

    //     this.classTicketRecordList = res
    //   } catch (error) {
    //     console.log(error)
    //     this.$message.error({
    //       message: error || error.message,
    //     })
    //   }
    // },

    // async getClassTicketRecordCount () {
    //   try {
    //     this.classTicketRecordCount = await GetClassTicketRecordCount({
    //       shopId: this.shop,
    //       code: this.search.code || undefined,
    //       MemberId: this.search.member ? this.search.member.id : undefined,
    //       ClassTicketId: this.search.classTicket || undefined,
    //     })
    //   } catch (error) {
    //     console.log(error)
    //     this.$message.error({
    //       message: error || error.message,
    //     })
    //   }
    // },
  // },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-input {
  @apply w-full max-w-[400px];
}
</style>
